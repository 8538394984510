<template>
  <div>
    <q-splitter
      class="full-height"
      v-model="splitterModel"
      style="height: 250px"
    >
      <template v-slot:before>
        <q-tabs v-model="tab" vertical class="text-teal">
          <q-tab
            name="核心能力"
            class="text-deep-orange-5 full-width material-icons"
            label="核心能力"
            >stars</q-tab
          >
          <q-tab
            name="日間部"
            class="text-deep-orange-5 full-width material-icons"
            label="日間部"
            >light_mode</q-tab
          >
          <q-tab
            name="進修部"
            class="text-deep-orange-5 full-width material-icons"
            label="進修部"
            >dark_mode</q-tab
          >
        </q-tabs>
      </template>

      <template v-slot:after>
        <q-tab-panels
          v-model="tab"
          animated
          vertical
          transition-prev="jump-up"
          transition-next="jump-up"
        >
          <q-tab-panel name="核心能力">
            <div class="q-pa-md q-gutter-md">
              <div class="text-h4 q-mb-md">
                核心能力與課程
              </div>
              <div class="text-subtitle1">
                創意設計 / 行銷管理 / 人文美學 / 數位應用
              </div>
              <template v-for="(data, idx) in courseList" :key="idx">
                <q-img
                  :src="data.image"
                  :ratio="1"
                  class="q-mt-md"
                  style="width: 250px"
                  @mouseenter="toggleEnter(idx)"
                  @mouseleave="toggleLeave(idx)"
                />
              </template>
            </div>
            <div class="q-pa-md">
              <q-video
                :ratio="16 / 9"
                src="https://www.youtube.com/embed/8cbx5X22eRg"
              />
            </div>
          </q-tab-panel>
          <q-tab-panel name="日間部">
            <div class="text-h4 q-mb-md">日間部</div>
            <div class="q-pa-md">
              <q-btn-group spread>
                <q-btn
                  color="blue-grey-6"
                  label="113日間部入學學生必選修科目表"
                  type="a"
                  href="https://fdm.kh.usc.edu.tw/var/file/35/1035/img/826/320731455.pdf"
                  target="_blank"
                />
                <q-btn
                  color="blue-grey-6"
                  label="113日間部入學學生模組課程-服經"
                  type="a"
                  href="https://fdm.kh.usc.edu.tw/var/file/35/1035/img/826/510398978.pdf"
                  target="_blank"
                />
              </q-btn-group>
              <q-btn-group spread>
                <q-btn
                  color="grey-6"
                  label="112日間部入學學生必選修科目表"
                  type="a"
                  href="https://fdm.kh.usc.edu.tw/var/file/35/1035/img/826/325777196.pdf"
                  target="_blank"
                />
                <q-btn
                  color="grey-6"
                  label="112日間部入學學生模組課程-服經"
                  type="a"
                  href="https://fdm.kh.usc.edu.tw/var/file/35/1035/img/826/155954793.pdf"
                  target="_blank"
                />
              </q-btn-group>
              <q-btn-group spread>
                <q-btn
                  color="blue-grey-6"
                  label="111日間部入學學生必選修科目表"
                  type="a"
                  href="https://fdm.kh.usc.edu.tw/var/file/35/1035/img/156864370.pdf"
                  target="_blank"
                />
                <q-btn
                  color="blue-grey-6"
                  label="111日間部入學學生模組課程-服經"
                  type="a"
                  href="https://fdm.kh.usc.edu.tw/var/file/35/1035/img/826/533593939.pdf"
                  target="_blank"
                />
              </q-btn-group>
              <q-btn-group spread>
                <q-btn
                  color="grey-6"
                  label="110日間部入學學生必選修科目表"
                  type="a"
                  href="https://fdm.kh.usc.edu.tw/var/file/35/1035/img/319648626.pdf"
                  target="_blank"
                />
                <q-btn
                  color="grey-6"
                  label="110日間部入學學生模組課程-服經"
                  type="a"
                  href="https://fdm.kh.usc.edu.tw/var/file/35/1035/img/826/277050720.pdf"
                  target="_blank"
                />
              </q-btn-group>
              <q-btn-group spread>
                <q-btn
                  color="blue-grey-6"
                  label="109日間部入學學生必選修科目表"
                  type="a"
                  href="https://fdm.kh.usc.edu.tw/var/file/35/1035/img/478602351.pdf"
                  target="_blank"
                />
                <q-btn
                  color="blue-grey-6"
                  label="109日間部入學學生模組課程-服經"
                  type="a"
                  href="https://rc024.kh.usc.edu.tw/var/file/35/1035/img/826/695888362.pdf"
                  target="_blank"
                />
              </q-btn-group>
              <q-btn-group spread>
                <q-btn
                  color="grey-6"
                  label="108日間部入學學生必選修科目表"
                  type="a"
                  href="https://fdm.kh.usc.edu.tw/var/file/35/1035/img/710004076.pdf"
                  target="_blank"
                />
                <q-btn
                  color="grey-6"
                  label="108日間部入學學生模組課程-服經"
                  type="a"
                  href="https://rc024.kh.usc.edu.tw/var/file/35/1035/img/826/864532370.pdf"
                  target="_blank"
                />
              </q-btn-group>
              <q-btn-group spread>
                <q-btn
                  color="blue-grey-6"
                  label="107日間部入學學生必選修科目表"
                  type="a"
                  href="https://rc024.kh.usc.edu.tw/var/file/35/1035/img/826/595620695.pdf"
                  target="_blank"
                />
                <q-btn
                  color="blue-grey-6"
                  label="107日間部入學學生模組課程-服經"
                  type="a"
                  href="https://rc024.kh.usc.edu.tw/var/file/35/1035/img/826/544800722.pdf"
                  target="_blank"
                />
              </q-btn-group>
            </div>
          </q-tab-panel>

          <q-tab-panel name="進修部">
            <div class="text-h4 q-mb-md">進修部</div>
            <div class="q-pa-md">
              <q-btn
                color="blue-grey-6"
                class="full-width"
                label="111進修部入學學生必選修科目表"
                type="a"
                href="https://fdm.kh.usc.edu.tw/var/file/35/1035/img/144623649.pdf"
                target="_blank"
              />
              <q-btn
                color="grey-6"
                class="full-width"
                label="110進修部入學學生必選修科目表"
                type="a"
                href="https://rc024.kh.usc.edu.tw/var/file/35/1035/img/826/852584482.pdf"
                target="_blank"
              />
              <q-btn
                color="blue-grey-6"
                class="full-width"
                label="109進修部入學學生必選修科目表"
                type="a"
                href="https://rc024.kh.usc.edu.tw/var/file/35/1035/img/826/143974071.pdf"
                target="_blank"
              />
              <q-btn
                color="grey-6"
                class="full-width"
                label="108進修部入學學生必選修科目表"
                type="a"
                href="https://rc024.kh.usc.edu.tw/var/file/35/1035/img/826/630580176.pdf"
                target="_blank"
              />
              <q-btn
                color="blue-grey-6"
                class="full-width"
                label="107進修部入學學生必選修科目表"
                type="a"
                href="https://rc024.kh.usc.edu.tw/var/file/35/1035/img/826/404881682.pdf"
                target="_blank"
              />
            </div>
          </q-tab-panel>
        </q-tab-panels>
      </template>
    </q-splitter>
  </div>
</template>

<script>
import { ref, reactive } from "vue";

export default {
  setup() {
    const courseTitleList = [
      {
        image:
          "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2521_8897295_82061.png"
      },
      {
        image:
          "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2523_9186856_82092.png"
      },
      {
        image:
          "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2525_3468941_82133.png"
      }
    ];
    const courseDetailList = [
      {
        image:
          "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2522_2544599_82076.png"
      },
      {
        image:
          "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2524_7020740_82110.png"
      },
      {
        image:
          "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2526_8141138_82165.png"
      }
    ];
    const courseList = reactive(JSON.parse(JSON.stringify(courseTitleList)));
    return {
      toggleEnter(el) {
        courseList[el].image = courseDetailList[el].image;
      },
      toggleLeave(el) {
        courseList[el].image = courseTitleList[el].image;
      },
      tab: ref("核心能力"),
      splitterModel: ref(20),
      courseList
    };
  },
  components: {}
};
</script>
