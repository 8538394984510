<template>
  <q-page>
    <CourseTabPanel />
  </q-page>
</template>

<script>
// @ is an alias to /src
// import CourseTree from "@/components/CourseTree.vue";
import CourseTabPanel from "@/components/CourseTabPanel.vue";

export default {
  setup() {
    return {};
  },
  name: "Course",
  components: { CourseTabPanel }
};
</script>
